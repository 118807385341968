import { RouteConfig } from 'vue-router';

// Views
import Home from '../../presentation/components/pages/Home.vue';

export default [
  { path: '*', redirect: '/' },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../../presentation/components/pages/About.vue'
      ),
  },
] as Array<RouteConfig>;
