






import { defineComponent, onMounted } from '@vue/composition-api';

export default defineComponent({
  name: 'App',
  setup(props, { root }) {
    onMounted(() => {
      root.$store.dispatch('events/getUserAndSessionInLocal');
    });
  },
});
