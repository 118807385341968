import Vue from 'vue';
import VueRouter from 'vue-router';

// Routes
import routes from './routes';

// Implementations
Vue.use(VueRouter);

// Instance
export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
