









import { defineComponent } from '@vue/composition-api';

// @ts-ignore
import Lottie from 'vue-lottie';
import Finder from '@/presentation/animations/index';

export default defineComponent({
  name: 'Animation',
  components: {
    lottie: Lottie,
  },
  props: {
    animation: String,
    height: Number,
    width: Number,
    loop: Boolean,
  },
  setup(props) {
    const { animation, loop = true } = props;
    return {
      defaultOptions: {
        animationData: Finder(animation),
        loop,
      },
    };
  },
});
