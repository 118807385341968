/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint no-var: off */
declare var QB: any;

const {
  VUE_APP_CHAT_APPLICATION_ID: APPLICATION_ID,
  VUE_APP_CHAT_AUTH_KEY: AUTH_KEY,
  VUE_APP_CHAT_AUTH_SECRET: AUTH_SECRET,
} = process.env;

QB.init(APPLICATION_ID ?? undefined, AUTH_KEY ?? '', AUTH_SECRET ?? '');

export default QB;
