import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import Events from './modules/Events';

// Initialize
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    events: Events,
  },
});
