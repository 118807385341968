


































































































import {
  watch,
  toRefs,
  computed,
  reactive,
  defineComponent,
  onMounted
} from '@vue/composition-api';

import 'vue2-animate/dist/vue2-animate.min.css';

// Components
import Animation from '@/presentation/components/atoms/Animation.vue';

// Definitions
import { ChatStatus } from '@/infrastructure/store/modules/Events';

export default defineComponent({
  name: 'Sidebar',
  components: {
    Animation,
  },
  props: {
    current: Number,
  },
  setup: (props, { emit, root }) => {
    const state = reactive({
      active: 'home',
      icon: 'bxs-skull',
      isProduction: process.env.NODE_ENV === 'production',
      titleLighted: false,
    });

    const calculated = {
      isLogged: computed(() => !!root.$store.getters['events/userId']),
      playerName: computed(() => root.$store.getters['events/userName']),
      playerEmail: computed(() => root.$store.getters['events/userEmail']),
      playerIsMaster: computed(
        () => root.$store.getters['events/userIsMaster'],
      ),
      messageCount: computed(
        () => root.$store.getters['events/messages'].length,
      ),
    };

    watch(
      () => state.active,
      current => {
        if (current) emit('click', current);
      },
    );

    watch(
      () => props.current,
      current => (state.active = 'home'),
    );

    watch(calculated.playerIsMaster, isMaster => {
      if (isMaster) state.icon = 'bxs-book-reader';
    });

    onMounted(() => {
      setTimeout(() => state.titleLighted = true, 2750);
    });

    return {
      ...calculated,
      ...toRefs(state),
    };
  },
});
