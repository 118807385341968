import { AES, enc } from 'crypto-js';
const secret = process.env.VUE_APP_CHAT_SECRET;

export const encrypt = (data: string | any) => {
  const current = typeof data === 'string' ? data : JSON.stringify(data);
  return AES.encrypt(current, secret).toString();
};

export const decrypt = (data: string) => {
  const bytes = AES.decrypt(data, secret);
  const current = bytes.toString(enc.Utf8);
  try {
    return JSON.parse(current);
  } catch (e) {
    return current;
  }
};
