/**+
 * Animations
 * - https://lottiefiles.com/Martov90
 * - https://lottiefiles.com/zuzuza
 * - https://lottiefiles.com/matheus.mesk
 * - https://lottiefiles.com/user/357446
 * - https://lottiefiles.com/user/26792
 * - https://lottiefiles.com/libertyink
 * - https://lottiefiles.com/calebbarclay
 * - https://lottiefiles.com/Nabil_mersni
 * - https://lottiefiles.com/jareds
 * - https://lottiefiles.com/rodetyo
 */
export const files = {
  magicChest: require('../animations/lottie/magic-chest.json'),
  chestClose: require('../animations/lottie/chest-close.json'),
  spaceTour: require('../animations/lottie/space-tour.json'),
  universeCanyon: require('../animations/lottie/universes-canyon.json'),
  universeFly: require('../animations/lottie/universes-fly.json'),
  universeNature: require('../animations/lottie/universes-nature.json'),
  universeSkiing: require('../animations/lottie/universes-skiing.json'),
  universeElk: require('../animations/lottie/universes-elk.json'),
  wizardFind: require('../animations/lottie/wizard-animation-find.json'),
  wizardHello: require('../animations/lottie/wizard-animation-hello.json'),
  wizardMage: require('../animations/lottie/wizard-animation-mage.json'),
};

export default function (name) {
  return files[name];
}
