/* eslint-disable @typescript-eslint/no-explicit-any */
import Cookies from 'js-cookie';
import { v5 as uuid5 } from 'uuid';
import { encrypt, decrypt } from '../../encryption';

// Services
import QB from '../../services/quickblox';

// Definitions
import { ActionContext } from 'vuex';
import { MessageFromPublicChat } from '@/infrastructure/services/quickblox/specs';

type Session = {
  application_id: number;
  created_at: Date;
  id: number;
  nonce: number;
  token: string;
  ts: number;
  updated_at: Date;
  user_id: number;
  _id: string;
};

type User = {
  id: number;
  full_name?: any;
  email?: any;
  login: string;
  phone?: any;
  website?: any;
  created_at: Date;
  updated_at: Date;
  last_request_at?: any;
  external_user_id?: any;
  facebook_id?: any;
  twitter_id?: any;
  blob_id?: any;
  custom_data?: any;
  age_over16: boolean;
  parents_contacts: string;
  user_tags?: any;
};

type Room = {
  _id: string;
  created_at?: Date;
  last_message?: any;
  last_message_date_sent?: any;
  last_message_id?: any;
  last_message_user_id?: any;
  name?: string;
  occupants_ids?: any[];
  photo?: any;
  type?: number;
  updated_at?: Date;
  user_id?: number;
  xmpp_room_jid?: string;
  unread_messages_count?: number;
};

export enum ChatStatus {
  none,
  login,
  created,
  shared,
  joined,
  leave,
}

type Message = {
  userId: number;
  message: MessageFromPublicChat;
};

type State = {
  chat: {
    session?: Session;
    user?: User;
    room?: Room;
    password?: string;
    status: ChatStatus;
    masterId: number;
    requireLogin: boolean;
  };
  messages: Message[];
  error: any;
};

type SignUpProps = {
  fullName: string;
  login: string;
  password: string;
};

type LoginProps = {
  fullName: string;
  password: string;
};

type JoinProps = {
  sharedCode: string;
  password?: string;
};

// Environments
const { VUE_APP_CHAT_NAMESPACE } = process.env;

export default {
  namespaced: true,
  state: (): State => ({
    chat: {
      user: undefined,
      room: undefined,
      session: undefined,
      password: '',
      status: ChatStatus.none,
      masterId: 0,
      requireLogin: false,
    },
    messages: [],
    error: undefined,
  }),
  getters: {
    userId: (state: State) => state.chat.user?.id,
    userName: (state: State) => state.chat.user?.full_name,
    userEmail: (state: State) => state.chat.user?.login,
    userIsMaster: (state: State) => state.chat.masterId === state.chat.user?.id,
    status: (state: State) => state.chat.status,
    messages: (state: State) => state.messages,
    existPassword: (state: State) => !!state.chat.password,
    roomSharedCode: (state: State) => {
      const { status, user } = state.chat;
      if (
        status !== ChatStatus.created ||
        state.chat.masterId !== state.chat.user?.id
      ) {
        return undefined;
      }
      return encrypt({
        roomId: state.chat.room?._id,
        master: {
          id: state.chat.user?.id,
          name: state.chat.user?.full_name,
        },
      });
    },
    requireLogin: (state: State) => state.chat.requireLogin,
  },
  mutations: {
    SAVE_USER_PASSWORD: (state: State, payload: string) => {
      state.chat.password = payload;
    },
    SAVE_CHAT_USER: (state: State, payload: User) => {
      state.chat.user = payload;
    },
    SAVE_CHAT_SESSION: (state: State, payload: Session) => {
      state.chat.session = payload;
    },
    SAVE_CHAT_STATUS: (state: State, payload: ChatStatus) => {
      state.chat.status = payload;
    },
    SAVE_CHAT_ROOM: (
      state: State,
      payload: { room: Room; userIsMaster?: boolean },
    ) => {
      state.chat.room = payload.room;
      if (payload.userIsMaster) state.chat.masterId = state.chat.user?.id ?? 0;
    },
    SAVE_CHAT_MESSAGE_LIST: (state: State, payload: Message[]) => {
      state.messages = payload;
    },
    SAVE_CHAT_MESSAGE: (state: State, payload: Message) => {
      state.messages.push(payload);
    },
    SAVE_CHAT_REQUIRE_LOGIN: (state: State, payload: boolean) => {
      state.chat.requireLogin = payload;
    },
    LOG_ERROR: (state: State, payload: any) => {
      state.error = payload;
    },
  },
  actions: {
    /**
     * Set client data to cookies
     * @param context
     * @param payload
     */
    setUserAndSessionInLocal: (context: any, payload: any) => {
      const { user, session } = payload;
      const encryptUser = encrypt(user);
      const encryptSession = encrypt(session);
      Cookies.set('_u', encryptUser);
      Cookies.set('_s', encryptSession);
    },
    /**
     * Remove client data of cookies
     */
    removeUserAndSessionInLocal: () => {
      Cookies.remove('_u');
      Cookies.remove('_s');
    },
    /**
     * Load client data from cookies
     * @param context
     */
    getUserAndSessionInLocal: (context: any) => {
      const session = Cookies.get('_s');
      const user = Cookies.get('_u');
      if (session && user) {
        const currentUser = decrypt(user);
        const currentSession = decrypt(session);
        context.commit('SAVE_CHAT_USER', currentUser);
        context.commit('SAVE_CHAT_SESSION', currentSession);
        context.commit('SAVE_CHAT_REQUIRE_LOGIN', true);
      } else {
        context.dispatch('removeUserAndSessionInLocal');
      }
    },
    /**
     * Initialize service without login resources
     * @param context
     */
    initialize: async (context: ActionContext<any, State>) => {
      if (context.state.token) return context.state.token;
      else {
        QB.session.create((error, result) => {
          if (!error) {
            context.commit('LOG_ERROR', undefined);
            context.commit('SAVE_CHAT_SESSION', result);
          } else {
            context.commit('LOG_ERROR', error);
            context.dispatch('removeUserAndSessionInLocal');
          }
        });
      }
    },
    /**
     * Initialize service with login resources
     * @param context
     * @param props
     */
    initializeWithLogin: (context: any, props: LoginProps) => {
      const { fullName, password } = props;
      QB.session.createWithLogin({
        fullName,
        password,
        callback: (error, response) => {
          if (!error) {
            context.commit('LOG_ERROR', undefined);
            context.commit('SAVE_CHAT_SESSION', response);
            context.commit('SAVE_USER_PASSWORD', password);
            context.dispatch('signIn', { fullName, password });
          } else {
            context.commit('LOG_ERROR', error);
            context.dispatch('removeUserAndSessionInLocal');
          }
        },
      });
    },
    /**
     * Register
     * @param context
     * @param props
     */
    signUp: (context: any, props: SignUpProps) => {
      const { fullName, login, password } = props;
      QB.user.signUp({
        fullName,
        login,
        password,
        callback: (error, response) => {
          if (!error) {
            context.commit('LOG_ERROR', undefined);
            context.commit('SAVE_CHAT_USER', response);
          } else {
            context.commit('LOG_ERROR', error);
            context.dispatch('removeClientData');
          }
        },
      });
    },
    /**
     * Login
     * @param context
     * @param props
     */
    signIn: (context: any, props: LoginProps) => {
      const { fullName, password } = props;
      QB.user.signIn({
        login: fullName,
        password,
        callback: (error, response) => {
          if (!error) {
            context.commit('LOG_ERROR', undefined);
            context.commit('SAVE_CHAT_USER', response);
            context.commit('SAVE_CHAT_REQUIRE_LOGIN', false);
            context.commit('SAVE_CHAT_STATUS', ChatStatus.login);
            context.dispatch('setUserAndSessionInLocal', {
              user: response,
              session: context.state.chat.session,
            });
          } else {
            context.commit('LOG_ERROR', error);
            context.dispatch('removeUserAndSessionInLocal');
          }
        },
      });
    },
    /**
     * Create public chat
     * @param context
     */
    createPublicDialog: (context: any) => {
      const {
        user: { id: userId },
        session: { token },
      } = context.state.chat;
      if (token && userId) {
        const roomId = uuid5(new Date() + userId, VUE_APP_CHAT_NAMESPACE);
        QB.chat.createPublic({
          name: roomId,
          token,
          callback: (error: any, response) => {
            if (!error) {
              context.commit('LOG_ERROR', undefined);
              context.commit('SAVE_CHAT_ROOM', {
                room: response,
                userIsMaster: true,
              });
              context.commit('SAVE_CHAT_STATUS', ChatStatus.created);
            } else {
              context.commit('LOG_ERROR', error);
              context.dispatch('removeUserAndSessionInLocal');
            }
          },
        });
      }
    },
    /**
     * Join public chat with shared code
     * @param context
     * @param props
     */
    joinPublicDialog: (context: any, props: JoinProps) => {
      const { sharedCode, password } = props;
      const data = decrypt(sharedCode);
      const {
        roomId: dialogId,
        master: { id, name },
      } = data;

      if (password) context.commit('SAVE_USER_PASSWORD', password);
      const userIsMaster = context.state.chat.user.id === id;

      QB.chat.join({
        dialogId,
        userId: context.state.chat.user.id,
        password: context.state.chat.password || password,
        callback: (error, result) => {
          if (error) context.commit('LOG_ERROR', error);
          else {
            context.commit('LOG_ERROR', undefined);
            context.commit('SAVE_CHAT_ROOM', {
              room: { _id: dialogId },
              userIsMaster,
            });
            context.commit('SAVE_CHAT_STATUS', ChatStatus.joined);
          }
        },
      });
    },
    /**
     * Retrieve dialogs
     * @param context
     */
    retrieveDialogs: (context: any) => {
      QB.chat.getMessages({
        token: context.state.chat.session.token,
        userId: context.state.chat.user.id,
        password: context.state.chat.user.password,
        dialogId: context.state.chat.room._id,
        callback: (error, dialogs) => {
          if (!error) {
            context.commit('LOG_ERROR', undefined);
            context.commit(
              'SAVE_CHAT_MESSAGE_LIST',
              dialogs.items.map(dialog => {
                const {
                  _id: id,
                  message: body,
                  fullName,
                  sender_id,
                  date,
                } = dialog;
                return {
                  userId: sender_id,
                  message: {
                    id,
                    body,
                    extension: {
                      fullName,
                      date,
                    },
                  },
                };
              }),
            );
          } else {
            context.commit('LOG_ERROR', error);
          }
        },
      });
    },
    /**
     * Send message
     * @param context
     * @param props
     */
    sendAndRetrieveMessages: (context: any, props: { message: Message }) => {
      const { _id: dialogId } = context.state.chat.room;
      const { full_name } = context.state.chat.user;
      QB.chat.sendAndRetrieveMessages({
        dialogId,
        message: props.message,
        extra: {
          fullName: full_name,
          date: new Date().toISOString(),
        },
        callback: (userId, message) => {
          context.commit('SAVE_CHAT_MESSAGE', {
            userId,
            message,
          });
        },
      });
    },
  },
};
