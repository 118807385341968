










import {
  watch,
  computed,
  defineComponent,
  SetupContext,
} from '@vue/composition-api';

// Components
import Dialog from '../../atoms/Dialog.vue';
import FormLogin from '../../molecules/FormLogin';

// Definitions
import { Response } from '../../molecules/FormLogin/FormLogin.specs';

export default defineComponent({
  name: 'DialogLogin',
  components: {
    Dialog,
    FormLogin,
  },
  props: {
    fullName: {
      type: String,
      required: false,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { root, emit }: SetupContext) {
    const calculated = {
      existPassword: computed(
        () => root.$store.getters['events/existPassword'],
      ),
    };

    const methods = {
      on: {
        complete: async (response: Response) => {
          await root.$store.dispatch('events/initializeWithLogin', response);
        },
        close: () => {
          emit('close', { logged: false });
        },
      },
    };

    watch(calculated.existPassword, existPassword => {
      if (existPassword) emit('close', { logged: true });
    });

    return {
      ...methods,
    };
  },
});
