











































import 'vue2-animate/dist/vue2-animate.min.css';
import { toRefs, reactive, watch, onMounted, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Cover',
  setup() {
    const state = reactive({
      showImage: false,
      showDeveloper: false,
      showText: false
    });

    onMounted(() => state.showImage = true);

    watch(
      () => state.showImage,
      (showImage) => {
        if (showImage) {
          setTimeout(() => state.showDeveloper = true, 1500);
          setTimeout(() => state.showText = true, 2300);
        }
      }
    );

    return {
      ...toRefs(state),
    };
  }
});
