import Vue from 'vue';
import vuesax from './vuesax';

// Styles
import 'vuesax/dist/vuesax.css';

// Icons
import 'boxicons';

// Theme implementation
Vue.use(vuesax, {
  colors: {
    primary: '#356859',
    success: '#37966F',
    danger: 'rgb(242, 19, 93)',
    warning: 'rgb(255, 130, 0)',
    dark: 'rgb(36, 33, 69)',
  },
});
