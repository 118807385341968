import Vue from 'vue';
import App from './App.vue';

// Infrastructure
import store from './infrastructure/store';
import router from './infrastructure/router';
import './infrastructure/theme';

// Config
Vue.config.productionTip = false;

// Composition API
import composition from '@vue/composition-api';
Vue.use(composition);

// Instance
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
