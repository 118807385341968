


































import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
} from '@vue/composition-api';

// Definitions
import { ChatStatus } from '@/infrastructure/store/modules/Events';

// Components
import Cover from '../atoms/Cover';
import Sidebar from '../organisms/Sidebar';
import DialogLogin from '../organisms/DialogLogin';

export default defineComponent({
  name: 'Home',
  components: {
    DialogSharedCode: () => import('../organisms/DialogSharedCode'),
    DialogEntryCode: () => import('../organisms/DialogEntryCode'),
    DialogRegister: () => import('../organisms/DialogRegister'),
    DialogList: () => import('../organisms/DialogList'),
    DialogLogin,
    Sidebar,
    Cover
  },
  setup(props, { root }) {
    // State
    const state = reactive({
      responseSidebar: '',
      chat: {
        notification: undefined,
        sharedCode: '',
      },
      shared: false,
      register: false,
      fullName: '',
      sidebarCurrent: 1,
    });

    // Calculated state
    const calculated = {
      sharedCode: computed(
        () => root.$store.getters['events/roomSharedCode'],
      ),
      chatStatus: computed(() => root.$store.getters['events/status']),
      userEmail: computed(() => root.$store.getters['events/userEmail']),
      requireLogin: computed(
        () => root.$store.getters['events/requireLogin'],
      ),
    };

    // Methods
    const methods = {
      on: {
        sidebarClick: (id: string) => {
          state.responseSidebar = id;
        },
        dialogClose: (props?: {
          logged?: boolean;
          register?: boolean;
          shared?: boolean;
          code?: string;
        }) => {
          state.responseSidebar = '';
          state.sidebarCurrent += 1;
          if (props && !calculated.requireLogin) {
            if (props.register) state.register = props.register;
            if (props.shared && props.code) {
              state.shared = props.shared;
            }
          }
        },
      },
    };

    watch(
      () => state.responseSidebar,
      sidebar => {
        if (sidebar === 'create-room') {
          // @ts-ignore
          state.chat.notification = root.$vs.notification({
            duration: 'none',
            sticky: true,
            border: '#356859',
            icon: `<i class='bx bxs-time'></i>`,
            title: 'Espere un momento ...',
            text: `Estamos creando tu partida`,
          });
          root.$store.dispatch('events/createPublicDialog');
        }
      },
    );

    watch(calculated.sharedCode, sharedCode => {
      if (sharedCode) {
        // @ts-ignore
        state.chat.notification.close();
        state.responseSidebar = 'shared-code';
        state.chat.sharedCode = sharedCode;
      }
    });

    watch(calculated.chatStatus, (status: ChatStatus) => {
      if (status === ChatStatus.joined) {
        methods.on.dialogClose();
      }
    });

    watch(
      [calculated.requireLogin, calculated.userEmail],
      ([requireLogin, email]) => {
        if (requireLogin && email) {
          state.fullName = email;
          state.responseSidebar = 'login';
        }
      },
    );

    return {
      ...methods,
      ...toRefs(state),
    };
  },
});
