/* eslint-disable camelcase */
import QuickBlox from './instance';

// Definitions
import {
  CreateSessionWithLoginProps,
  CreateSessionCallback,
  DestroySessionCallback,
  SignUpUserProps,
  LogInUserProps,
  LogOutUserProps,
  ChatCreatePublicProps,
  ChatConnectProps,
  ChatJoinProps,
  ChatLeaveProps,
  ChatLeaveList,
  ChatGetMessageProps,
  ChatSendMessageProps,
} from './methods.specs';

export default {
  session: {
    createWithLogin: (props: CreateSessionWithLoginProps) => {
      const { fullName, password, callback } = props;
      return QuickBlox.createSession({ fullName, password }, callback);
    },
    create: (callback: CreateSessionCallback) => {
      return QuickBlox.createSession(callback);
    },
    destroy: (callback: DestroySessionCallback) => {
      return QuickBlox.destroySession(callback);
    },
  },
  user: {
    signUp: (props: SignUpUserProps) => {
      const { login, password, fullName, callback } = props;
      return QuickBlox.users.create(
        { login, password, full_name: fullName },
        callback,
      );
    },
    signIn: (props: LogInUserProps) => {
      const { login, password, callback } = props;
      return QuickBlox.login({ login, password }, callback);
    },
    logOut: (props: LogOutUserProps) => {
      return QuickBlox.logout(props.callback);
    },
  },
  chat: {
    createPublic: (props: ChatCreatePublicProps) => {
      const { name, token, callback } = props;
      return QuickBlox.chat.dialog.create({ type: 1, token, name }, callback);
    },
    join: (props: ChatJoinProps) => {
      const { userId, password, dialogId, callback } = props;
      QuickBlox.chat.connect(
        { userId, password },
        (error: any, response: any) => {
          if (response) {
            const dialogJid = QuickBlox.chat.helpers.getRoomJidFromDialogId(
              dialogId,
            );
            return QuickBlox.chat.muc.join(dialogJid, callback);
          }
        },
      );
    },
    leave: (props: ChatLeaveProps) => {
      const { dialogId, callback } = props;
      const joinId = QuickBlox.chat.helpers.getRoomJidFromDialogId(dialogId);
      return QuickBlox.chat.muc.leave(joinId, callback);
    },
    list: (props: ChatLeaveList) => {
      return QuickBlox.chat.dialog.list(props.data, props.callback);
    },
    getMessages: (props: ChatGetMessageProps) => {
      const { userId, password, callback, token, dialogId } = props;
      const params = {
        chat_dialog_id: dialogId,
        token: token,
        sort_desc: 'date_sent',
        limit: 100,
        skip: 0,
      };
      return QuickBlox.chat.message.list(params, callback);
    },
    sendAndRetrieveMessages: (props: ChatSendMessageProps) => {
      const { dialogId, extra, message, callback } = props;
      const joinId = QuickBlox.chat.helpers.getRoomJidFromDialogId(dialogId);

      // Message config
      const config = {
        id: undefined,
        type: 'groupchat',
        body: message,
        extension: {
          save_to_history: 1,
          dialog_id: dialogId,
          ...extra,
        },
        markable: 1,
      };

      // Send message
      config.id = QuickBlox.chat.send(joinId, config);

      // Set callback
      return (QuickBlox.chat.onMessageListener = callback);
    },
  },
};
