
































































import {
  watch,
  toRefs,
  reactive,
  computed,
  defineComponent,
  SetupContext,
  onBeforeUnmount,
} from '@vue/composition-api';

// Definitions
import { Response } from './FormLogin.specs';

type Props = {
  fullName: string;
};

export default defineComponent({
  name: 'FormLogin',
  props: {
    fullName: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup: (props: Props, { emit, root }: SetupContext) => {
    const state = reactive({
      name: props.fullName,
      password: '',
      loading: false,
      disabled: false,
      disabledButton: false,
    });

    const calculated = {
      formValid: computed(() => !!(state.name && state.password)),
      requireLogin: computed(
        () => root.$store.getters['events/requireLogin'],
      ),
    };

    const methods = {
      on: {
        clickCloseButton: () => emit('close'),
        clickLoginButton: () => {
          const { name, password } = state;
          state.disabled = true;
          state.loading = true;
          state.disabledButton = true;
          emit('complete', { fullName: name, password } as Response);
        },
      },
    };

    watch(calculated.formValid, isValid => {
      state.disabled = !isValid;
    });

    onBeforeUnmount(() => {
      state.name = '';
      state.password = '';
      state.loading = false;
      state.disabled = false;
    });

    return {
      ...methods,
      ...calculated,
      ...toRefs(state),
    };
  },
});
