









import {
  watch,
  toRefs,
  reactive,
  defineComponent,
} from '@vue/composition-api';

type Properties = {
  title: string;
  open: boolean;
  notClose?: boolean;
  preventClose?: boolean;
};

export default defineComponent({
  name: 'Dialog',
  props: {
    title: String,
    open: Boolean,
    notClose: Boolean,
    preventClose: Boolean,
  },
  setup(props: Properties) {
    const state = reactive({
      isOpen: false,
    });

    watch(
      () => props.open,
      current => {
        state.isOpen = !!current;
      },
    );

    return { ...toRefs(state) };
  },
});
